import type { MediaImageInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/types/MediaImage.vue';
import type { Props as TeaserCardProps } from '@hypercodestudio/basler-components/dist/components/modules/TeaserCard.vue';
import type { AlgoliaContentfulPage } from '~/lib/AlgoliaService/models/AlgoliaContentfulPage';
import { generateImageObject } from '~/utils/generateImageObject';
import { DETAILED_TAG } from '~/utils/shop/constants';

export function transformAlgoliaContentfulPageToTeaserCard(
  page: Pick<
    AlgoliaContentfulPage,
    | 'teaser_image_url'
    | 'teaser_image_alt'
    | 'teaser_description'
    | 'teaser_title'
    | 'url'
    | 'detailed_topics'
    | 'date'
  >,
  locale: string,
  showDate?: boolean | undefined
): TeaserCardProps {
  const image = generateImageObject(page.teaser_image_url, {
    fit: 'pad',
    bgColor: 'rgb:ffffff'
  });
  if (image) {
    (image.content as MediaImageInterface).alt = page.teaser_image_alt;
  }

  return {
    header: image ?? {},
    content: page.teaser_description,
    date:
      showDate && page.date
        ? {
            date: new Date(page.date).toLocaleDateString(locale),
            dateIso: page.date
          }
        : undefined,
    link: buildLinkInterface(page.url, locale),
    headline: page.teaser_title,
    tagList: {
      tags: page.detailed_topics?.map((tag) => ({
        text: tag.split(DETAILED_TAG)[0]
      }))
    }
  };
}
